import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { routes as appRoutes } from './routes';
import 'styles/bases.css';
import { Blockchain } from './containers/global';
import { CustomToastContainer } from './components/toast/ToastContainer';
import NotFound from './containers/notFound/NotFound';
import { Header } from './containers/header';
import { GlobalModals } from './components/modal/globalModals';
import { Footer } from './containers/footer';
import { media, sizes } from './styles/media';
import { mediaQueries } from './styles/mediaQueries';
import { ScrollToTop } from './utils/scrollToTop';

// define theme
const theme = createTheme({
  palette: {
    primary: {
      light: '#6eff5e',
      main: '#2CEF32',
      dark: '#1bde21',
      contrastText: '#000',
    },
    secondary: {
      main: '#4db6ac',
      light: '#82e9de',
      dark: '#00867d',
      contrastText: '#000',
    },
  },
});
export function App() {
  return (
    <ThemeProvider theme={theme}>
      <CustomToastContainer />
      <CssBaseline />
      <AppContainer>
        <Blockchain />
        <Router>
          <ScrollToTop />
          <ContentContainer>
            <Header />
            <GlobalModals />
            <Routes>
              {appRoutes.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
              <Route path="*" element={<NotFound />}></Route>
            </Routes>
            <Footer />
          </ContentContainer>
        </Router>
      </AppContainer>
    </ThemeProvider>
  );
}
const AppContainer = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
  overflow-x: hidden;
  width: 100vw;
  /* background-color: var(--light); */
`;

const ContentContainer = styled(Box)`
  min-height: calc(100vh);
  box-sizing: border-box;
  color: var(--black);
  /* ${mediaQueries.lessThan('xl')`width: 90%;
  `}
  max-width: 1320px;
  margin: auto; */
`;
export function WrappedApp() {
  return <App />;
}
