import { useDispatch, useSelector } from 'react-redux';

import { EmailIsSetContent } from './content';
import { Modal } from '@/components/modal';
import { GlobalSelectors } from '@/containers/global/selectors';
import { globalActions } from '@/containers/global/slice';

export const EmailIsSet = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(
    GlobalSelectors.isWallMoneyEmailSuccessfullySetModalOpen
  );
  const handleModalClose = () => {
    dispatch(
      globalActions.setGlobalModalOpen({
        modalName: 'wallMoneyEmailSuccessfullySet',
        isOpen: false,
      })
    );
  };

  return (
    <Modal
      title={'Email set successfully.'}
      isOpen={isOpen}
      onClose={handleModalClose}
    >
      <EmailIsSetContent />
    </Modal>
  );
};
