import { delay, put, select, takeLatest } from 'redux-saga/effects';
import { globalActions } from './slice';
import { addOrUpdateKVItem, readKVItem } from '@/utils/kvItemUtils';
import { GlobalSelectors } from './selectors';
import { GlobalState } from './types';
import { toast } from 'react-toastify';

function* checkIfWalletIsRegistered(
  action: ReturnType<typeof globalActions.checkIfWalletIsRegistered>
) {
  try {
    yield put(globalActions.setIsCheckingIfWalletIsRegistered(true));
    const response: { status: number; response: string } = yield readKVItem(
      action.payload.coreId
    );
    if (response && response?.status === 200 && response.response) {
      yield put(globalActions.setIsEmailRegistered(true));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(globalActions.setIsCheckingIfWalletIsRegistered(false));
  }
}

function* registerWallet(
  action: ReturnType<typeof globalActions.registerWallet>
) {
  const { email } = action.payload;
  const connection: GlobalState['connection'] = yield select(
    GlobalSelectors.connection
  );
  const account = connection?.account || '';
  if (!account) {
    toast.error('Please connect to your wallet');
  }
  try {
    yield put(globalActions.setIsRegisteringWallet(true));
    const response: string | undefined = yield addOrUpdateKVItem(
      account,
      email
    );
    if (response) {
      //   yield delay(3000);
      yield put(globalActions.setIsEmailRegistered(true));
      yield put(
        globalActions.setGlobalModalOpen({
          isOpen: false,
          modalName: 'setupWallMoneyEmail',
        })
      );
      yield put(
        globalActions.setGlobalModalOpen({
          isOpen: true,
          modalName: 'wallMoneyEmailSuccessfullySet',
        })
      );
      //   yield put(globalActions.checkIfWalletIsRegistered({ coreId: account }));
    }
  } catch (error) {
    console.log(error);
    toast.error('Something went wrong');
  } finally {
    yield put(globalActions.setIsRegisteringWallet(false));
  }
}

function* setConnection() {
  yield put(
    globalActions.setGlobalModalOpen({
      modalName: 'connectToCorePort',
      isOpen: false,
    })
  );
}

export function* globalSaga() {
  yield takeLatest(
    globalActions.checkIfWalletIsRegistered.type,
    checkIfWalletIsRegistered
  );
  yield takeLatest(globalActions.setConnection.type, setConnection);
  yield takeLatest(globalActions.registerWallet.type, registerWallet);
}
