import { IRoute } from './interfaces';
import { PlanPage } from './containers/home/loadable';
import { HomePage } from './containers/whyWallMoney/loadable';
export enum AppPages {
  Home = '/',
  PlanPage = '/Plans',
}
export const routes: Array<IRoute> = [
  {
    key: 'home-route',
    title: 'Home',
    path: AppPages.Home,
    enabled: true,
    component: HomePage,
  },
  {
    key: 'whyWallMoneyPage-route',
    title: 'plan-page',
    path: AppPages.PlanPage,
    enabled: true,
    component: PlanPage,
  },
];
