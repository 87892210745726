import { AppPages } from '@/routes';
import {
  COREPORT_GOOGLE_URL,
  CONTACT_US_URL,
  GENERATE_PAY_TO_URL,
} from '../../constants/Links';

export const footerNavLinksCol1 = [
  {
    title: 'Lifestyle',
    path: '/',
  },
  {
    title: 'Enter the platform',
    path: 'scroll',
  },
  {
    title: 'Download CorePort',
    path: COREPORT_GOOGLE_URL,
  },
  {
    title: 'Generate PayTo',
    path: GENERATE_PAY_TO_URL,
  },
  {
    title: 'CorePass application',
    path: 'https://corepass.net',
  },
  {
    title: 'Core Blockchain',
    path: 'https://coreblockchain.net',
  },
];
export const footerNavLinksCol2 = [
  {
    title: 'Help',
    path: '/',
  },
  // {
  //   title: 'Terms of Service',
  //   path: '',
  // },
  // {
  //   title: 'Privacy Policy',
  //   path: '',
  // },
  {
    title: 'Concierge service',
    path: 'https://wallmoneymembers.com/chat/default',
  },
  {
    title: 'Support',
    path: CONTACT_US_URL,
  },
  // {
  //   title: 'Sitemap',
  //   path: '/',
  // },
];
export const footerNavLinksCol3 = [
  {
    title: 'Plans',
    path: AppPages.PlanPage,
  },
  {
    title: 'Ruby',
    path: AppPages.PlanPage,
  },
  {
    title: 'Emerald',
    path: AppPages.PlanPage,
  },
  {
    title: 'Sapphire',
    path: AppPages.PlanPage,
  },
  {
    title: 'Pearl',
    path: AppPages.PlanPage,
  },
];
