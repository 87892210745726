import { styled } from '@mui/material';
import React, { FC } from 'react';
interface Props {
  className?: string;
}
export const LinearDivider: FC<Props> = (props) => {
  return <Wrapper className={props.className} />;
};

const Wrapper = styled('div')`
  background-color: var(--light);
  width: 100%;
  height: 1px;
  margin: 44px 0;
`;
