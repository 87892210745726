import { AppPages } from '@/routes';

export const navItems = [
  {
    title: 'Home',
    path: '/',
  },
  {
    title: 'Plans',
    path: AppPages.PlanPage,
  },

  // {
  //   title: 'Discover',
  //   path: '/',
  // },
  // {
  //   title: 'Why wallmoney',
  //   path: AppPages.PlanPage,
  // },
  // {
  //   title: 'Coreport',
  //   path: '/',
  // },
  // {
  //   title: 'transactions',
  //   path: '/',
  // },
  // {
  //   title: 'exchange',
  //   path: '/',
  // },
];
