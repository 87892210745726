import { RootState } from '@/store/types';
import { createSelector } from '@reduxjs/toolkit';

export const GlobalDomains = {
  root: (state: RootState) => state,
  globalModals: (state: RootState) => state.global.globalModals,
  isCheckingIfWalletIsRegistered: (state: RootState) =>
    state.global.isCheckingIfWalletIsRegistered,
  connection: (state: RootState) => state.global.connection,
  isWalletRegistered: (state: RootState) => state.global.isWalletRegistered,
  isRegisteringWallet: (state: RootState) => state.global.isRegisteringWallet,
};

export const GlobalSelectors = {
  isConnectToCorePortModalOpen: createSelector(
    GlobalDomains.globalModals,
    (globalModals) => globalModals.connectToCorePort.isOpen
  ),
  isInstallCorePortModalOpen: createSelector(
    GlobalDomains.globalModals,
    (globalModals) => globalModals.installCorePort.isOpen
  ),
  isSetupWallMoneyEmailModalOpen: createSelector(
    GlobalDomains.globalModals,
    (globalModals) => globalModals.setupWallMoneyEmail.isOpen
  ),
  isWallMoneyEmailSuccessfullySetModalOpen: createSelector(
    GlobalDomains.globalModals,
    (globalModals) => globalModals.wallMoneyEmailSuccessfullySet.isOpen
  ),
  isCheckingIfWalletIsRegistered: createSelector(
    GlobalDomains.isCheckingIfWalletIsRegistered,
    (isCheckingIfWalletIsRegistered) => isCheckingIfWalletIsRegistered
  ),
  connection: createSelector(
    GlobalDomains.connection,
    (connection) =>
      connection || {
        account: undefined,
        connect: undefined,
      }
  ),
  isWalletRegistered: createSelector(
    GlobalDomains.isWalletRegistered,
    (isWalletRegistered) => isWalletRegistered
  ),
  isRegisteringWallet: createSelector(
    GlobalDomains.isRegisteringWallet,
    (isRegisteringWallet) => isRegisteringWallet
  ),
};
