import styled from '@emotion/styled';
import { FC, ReactElement } from 'react';
import { Box } from '@mui/material';
import { ROW_JUSTIFY_START__ALIGN_CENTER } from '@/styles/globalStyles';
import { TEXT_12_400 } from '@/styles/globalTypography';

export const FooterDescription: FC = (): ReactElement => {
  return (
    <SocialContainer>
      WallMoney Lifestyle Platform is a membership platform. Financial services
      are provided through our partner, Nvayo, which is not a bank.
    </SocialContainer>
  );
};

const SocialContainer = styled('p')`
  ${TEXT_12_400}
  color: var(--grey);
  width: 100%;
`;
