import { useDispatch, useSelector } from 'react-redux';

import { InstallCoreportContent } from './content';
import { Modal } from '@/components/modal';
import { GlobalSelectors } from '@/containers/global/selectors';
import { globalActions } from '@/containers/global/slice';

export const InstallCoreport = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(GlobalSelectors.isInstallCorePortModalOpen);
  const handleModalClose = () => {
    dispatch(
      globalActions.setGlobalModalOpen({
        modalName: 'installCorePort',
        isOpen: false,
      })
    );
  };

  return (
    <Modal
      title={'You have not installed CorePort in your browser.'}
      isOpen={isOpen}
      onClose={handleModalClose}
    >
      <InstallCoreportContent />
    </Modal>
  );
};
