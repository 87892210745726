import { styled } from '@mui/material';

export const ChromeIcon = (props: any) => {
  return (
    <Wrapper {...props}>
      <svg
        width="40"
        height="41"
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.8792 0.16028C19.8792 0.16028 31.6258 -0.366851 37.8535 11.4402H18.8796C18.8796 11.4402 15.2989 11.3248 12.2401 15.6688C11.3615 17.4921 10.417 19.3703 11.4769 23.0717C9.95009 20.4851 3.37109 9.02949 3.37109 9.02949C3.37109 9.02949 8.01156 0.627056 19.879 0.16028H19.8792Z"
          fill="#EF3F36"
        />
        <path
          d="M37.2424 30.1341C37.2424 30.1341 31.822 40.5738 18.4883 40.0576C20.1358 37.2073 27.9779 23.6264 27.9779 23.6264C27.9779 23.6264 29.8726 20.584 27.6374 15.7623C26.5005 14.0873 25.3418 12.3355 21.6076 11.4018C24.6116 11.3745 37.8192 11.4018 37.8192 11.4018C37.8192 11.4018 42.767 19.623 37.2424 30.1341Z"
          fill="#FCD900"
        />
        <path
          d="M2.59521 30.2166C2.59521 30.2166 -3.73675 20.3041 3.38045 9.01318C5.02251 11.8634 12.8646 25.4444 12.8646 25.4444C12.8646 25.4444 14.5561 28.6076 19.8445 29.0797C21.8601 28.9315 23.9579 28.8052 26.638 26.0429C25.1605 28.6571 18.5319 40.0797 18.5319 40.0797C18.5319 40.0797 8.93248 40.2555 2.59506 30.2166H2.59521Z"
          fill="#61BC5B"
        />
        <path
          d="M18.4834 40.157L21.1523 29.0199C21.1523 29.0199 24.0849 28.7891 26.5452 26.0928C25.0184 28.7783 18.4834 40.157 18.4834 40.157Z"
          fill="#5AB055"
        />
        <path
          d="M10.9375 20.2822C10.9375 15.3727 14.9191 11.3911 19.8285 11.3911C24.738 11.3911 28.7196 15.3727 28.7196 20.2822C28.7196 25.1918 24.738 29.1732 19.8285 29.1732C14.9191 29.1677 10.9375 25.1918 10.9375 20.2822Z"
          fill="white"
        />
        <path
          d="M12.4287 20.2817C12.4287 16.1959 15.7403 12.8789 19.8316 12.8789C23.9174 12.8789 27.2344 16.1905 27.2344 20.2817C27.2344 24.3675 23.923 27.6846 19.8316 27.6846C15.7457 27.6846 12.4287 24.3675 12.4287 20.2817Z"
          fill="url(#paint0_linear_79_1300)"
        />
        <path
          d="M37.813 11.4077L26.8241 14.6313C26.8241 14.6313 25.1656 12.1984 21.6016 11.4077C24.6934 11.3912 37.813 11.4077 37.813 11.4077Z"
          fill="#EACA05"
        />
        <path
          d="M11.2628 22.6656C9.71943 19.9911 3.37109 9.02979 3.37109 9.02979L11.5098 17.0805C11.5098 17.0805 10.675 18.7995 10.9881 21.2597L11.2626 22.6656H11.2628Z"
          fill="#DF3A32"
        />
        <defs>
          <linearGradient
            id="paint0_linear_79_1300"
            x1="19.8312"
            y1="12.9834"
            x2="19.8312"
            y2="27.2389"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#86BBE5" />
            <stop offset="1" stopColor="#1072BA" />
          </linearGradient>
        </defs>
      </svg>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  cursor: pointer;
`;
