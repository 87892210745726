import {
  COLUMN_ALIGN_CENTER__SPACE_B,
  COLUMN_ALIGN_START__SPACE_B,
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_ALIGN_START__SPACE_B,
} from '@/styles/globalStyles';
import styled from '@emotion/styled';
import { FC, ReactElement } from 'react';
import Icon from '@/components/icon';
import { WhiteBGButton } from '@/components/button/whiteBg';
import { Box } from '@mui/material';
import {
  TEXT_15_400,
  TEXT_11_700,
  TEXT_96_400,
  TEXT_16_400,
  TEXT_14_400,
} from '@/styles/globalTypography';
import linkedInIcon from '../../../assets/svg/linkedIn.svg';
import telegramIcon from '../../../assets/svg/telegram.svg';
import discordIcon from '../../../assets/svg/discord.svg';
import xIcon from '../../../assets/svg/x.svg';
import { Link } from 'react-router-dom';

export const FooterSocial: FC = (): ReactElement => {
  return (
    <SocialContainer>
      <SocialLeftCol>© 2023 WallMoney All rights reserved.</SocialLeftCol>
      <SocialRighttCol>
        <Link to="/">
          <Icon src={linkedInIcon} />
        </Link>
        <Link to="/">
          <Icon src={telegramIcon} />
        </Link>
        <Link to="/">
          <Icon src={discordIcon} />
        </Link>
        <Link to="/">
          <Icon src={xIcon} />
        </Link>
      </SocialRighttCol>
    </SocialContainer>
  );
};

const SocialContainer = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  width: 100%;
`;

const SocialLeftCol = styled('p')`
  ${TEXT_14_400}
  color: var(--grey)
`;

const SocialRighttCol = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  gap:32px
`;
