import { useDispatch, useSelector } from 'react-redux';
import { Box, Tooltip, styled } from '@mui/material';

import Icon from '@/components/icon';

import CorePortIcon from 'assets/svg/coreport.svg';

import { COLUMN_ALIGN_START__JUSTIFY_CENTER } from '@/styles/globalStyles';
import { H3_BOLD, TEXT_SM_REGULAR } from '@/styles/globalTypography';
import { GlobalSelectors } from '@/containers/global/selectors';
import BaseButton from '@/components/button/basebutton';
import { globalActions } from '@/containers/global/slice';
import SimpleInput from '@/components/inputs/simple';
import { addressMinimizer } from '@/utils/formatters';
import { CopyIcon } from '@/assets/copyIcon';
// @ts-ignore
import { validate } from 'react-email-validator';
import { useState } from 'react';
import { destinationEmailBase } from '@/constants/config';
import { PrimaryButton } from '@/components/button/primary';

export const SetupYourEmailContent = () => {
  const dispatch = useDispatch();
  const { account } = useSelector(GlobalSelectors.connection);
  const [showTooltip, setShowTooltip] = useState(false);
  const isregistering = useSelector(GlobalSelectors.isRegisteringWallet);
  const [personalEmailValue, setPersonalEmailValue] = useState('');
  const close = () => {
    dispatch(
      globalActions.setGlobalModalOpen({
        isOpen: false,
        modalName: 'installCorePort',
      })
    );
  };
  const coreEmail =
    addressMinimizer(account, {
      maxChar: 15,
    }) +
    '@' +
    destinationEmailBase;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(account + '@' + destinationEmailBase);
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  };
  const register = () => {
    dispatch(
      globalActions.registerWallet({
        email: personalEmailValue,
      })
    );
  };
  const isEmailValid = (email: string) => {
    return validate(email);
  };
  return (
    <Wrapper>
      <DiscriptionContainer>
        <Discription>
          We have identified your CoreID and Email. For more convenient use of
          the application, please link your e-mail, where all information will
          be automatically forwarded to you.
        </Discription>
        <Discription>
          <Title>Wallmoney email (for log in)</Title>
          <InputWrapper>
            <SimpleInput
              value={coreEmail}
              disabled
              fullWidth
              errormessage={undefined}
              touched
            />
            <Tooltip title="Copied!" open={showTooltip} arrow placement="top">
              <StyledCopyIcon onClick={handleCopyClick}>
                <CopyIcon />
              </StyledCopyIcon>
            </Tooltip>
          </InputWrapper>
          <Title>enter your email (personal) *</Title>
          <SimpleInput
            value={personalEmailValue}
            onChange={(e) => {
              setPersonalEmailValue(e.target.value);
            }}
            fullWidth
            errormessage={
              isEmailValid(personalEmailValue) || personalEmailValue === ''
                ? undefined
                : 'Invalid Email'
            }
            touched
          />
        </Discription>
        <SubmitButton
          color="secondary"
          isLoading={isregistering}
          disabled={!isEmailValid(personalEmailValue)}
          onClick={register}
        >
          Proceed
        </SubmitButton>
      </DiscriptionContainer>
    </Wrapper>
  );
};

const StyledCopyIcon = styled(Box)`
  position: absolute;
  top: 20px;
  right: 16px;
`;

const Title = styled('span')`
  ${H3_BOLD}
  color:var(--lightText);
  font-size: 14px;
  text-align: start;
  margin-bottom: -24px;
`;

const Wrapper = styled('div')`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 40px;
`;

const InputWrapper = styled('div')`
  position: relative;
`;

const DiscriptionContainer = styled('div')`
  width: 100%;
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap:16px;
`;

const SubmitButton = styled(PrimaryButton)`
  height: 64px;
  width: 100%;
  background: transparent;
  border: 1px solid var(--light);
  color: var(--darkText);
  ${H3_BOLD}
  font-size: 18px;
`;

const Discription = styled('div')`
  ${TEXT_SM_REGULAR}
  display: flex;
  gap: 12px;
  text-align: left;
  color: var(--Neutrals-N100);
  flex-direction: column;
  width: 100%;
`;
