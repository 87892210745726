import {
  COLUMN_ALIGN_CENTER__SPACE_B,
  ContenWidthSetting,
} from '@/styles/globalStyles';
import styled from '@emotion/styled';
import { FC, ReactElement } from 'react';
import { ContactUs } from './components/contactUs';
import { FooterNavbar } from './components/navbar';
import { LinearDivider } from '@/components/divider';
import { FooterSocial } from './components/social';
import { FooterDescription } from './components/description';

export const Footer: FC = (): ReactElement => {
  return (
    <FooterContainer>
      <ContactUs />
      <FooterNavbar />
      <LinearDivider />
      <FooterSocial />
      <LinearDivider />
      <FooterDescription />
    </FooterContainer>
  );
};

const FooterContainer = styled('footer')`
  ${ContenWidthSetting}
  ${COLUMN_ALIGN_CENTER__SPACE_B}
  margin-bottom: 100px;
`;
