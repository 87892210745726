import {
  accountId,
  destinationEmailBase,
  namespaceId,
  cloudflareProxyWorkerUrl,
} from '@/constants/config';

const prefix = '';
const headers: any = {
  'Content-Type': 'application/json',
};

export const addOrUpdateKVItem = async (coreId: string, redirect: string) => {
  const coreEmail = coreId + '@' + destinationEmailBase;
  const url = `${prefix}${accountId}/storage/kv/namespaces/${namespaceId}/values/${coreEmail}`;

  const emailMapping = redirect;

  const response = await fetch(cloudflareProxyWorkerUrl, {
    method: 'PUT',
    headers,
    body: JSON.stringify({ url: url, body: emailMapping }),
  });

  if (response.ok) {
    const jsonResponse = await response.json();
    return {
      result: jsonResponse.result,
      success: jsonResponse.success || false,
    };
  } else {
    const jsonResponse = await response.json();
    throw Error(
      'Error adding or updating key-value pair:',
      jsonResponse.errors
    );
  }
};

export const readKVItem = async (coreId: string) => {
  const coreEmail = coreId + '@' + destinationEmailBase;
  const url = `${prefix}${accountId}/storage/kv/namespaces/${namespaceId}/values/${coreEmail}`;

  const response = await fetch(cloudflareProxyWorkerUrl, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify({ url: url }),
  });

  if (response.ok) {
    const text = await response.text();
    return {
      response: text,
      status: response.status,
    };
  } else {
    const jsonResponse = await response.json();
    throw Error('Error reading key-value pair:', jsonResponse.errors);
  }
};
