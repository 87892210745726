import {
  ContenWidthSetting,
  ROW_ALIGN_CENTER__SPACE_B,
} from '@/styles/globalStyles';
import styled from '@emotion/styled';
import { FC, ReactElement } from 'react';
import { Navbar } from './components/navbar';
import Icon from '@/components/icon';
import whiteBGLogo from '../../assets/svg/whiteBGLogo.png';
import greyBGLogo from '../../assets/svg/greyBGLogo.png';
import { Box } from '@mui/material';
import { ConnectButton } from './components/connectButton';
import { GreyBGContainer } from '../global/backgroundContainers';
import { NavLink, useLocation } from 'react-router-dom';
import { AppPages } from '@/routes';

export const Header: FC = (): ReactElement => {
  const { pathname } = useLocation();

  return (
    <StyledGreyBGContainer location={pathname}>
      <HeaderContainer>
        <NavLink to="/">
          <Icon
            src={pathname === AppPages.PlanPage ? whiteBGLogo : greyBGLogo}
          />
        </NavLink>
        <RightColumnWrapper>
          <Navbar />
          <ConnectButton location={pathname} />
        </RightColumnWrapper>
      </HeaderContainer>
    </StyledGreyBGContainer>
  );
};

const HeaderContainer = styled('header')`
  ${ROW_ALIGN_CENTER__SPACE_B}
  ${ContenWidthSetting}
  width: 100%;
  background-color: var(--Black);
  padding: 32px 0px;
`;
const RightColumnWrapper = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  gap: 10px
`;

const StyledGreyBGContainer = styled(GreyBGContainer)<{ location: string }>`
  // set the background color based on the location
  background-color: ${(props) => {
    if (props.location === AppPages.Home) {
      return 'var(--white)';
    }
    return 'var(--light)';
  }};
`;
