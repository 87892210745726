import { useDispatch, useSelector } from 'react-redux';

import { ConnectToWalletContent } from './content';
import { Modal } from '@/components/modal';
import { GlobalSelectors } from '@/containers/global/selectors';
import { globalActions } from '@/containers/global/slice';

export const ConnectToWalletModal = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(GlobalSelectors.isConnectToCorePortModalOpen);
  const handleModalClose = () => {
    dispatch(
      globalActions.setGlobalModalOpen({
        modalName: 'connectToCorePort',
        isOpen: false,
      })
    );
  };

  return (
    <Modal title={'Wallmoney login'} isOpen={isOpen} onClose={handleModalClose}>
      <ConnectToWalletContent />
    </Modal>
  );
};
