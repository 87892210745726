import { WhiteBGButton } from '@/components/button/whiteBg';
import { platformAddress } from '@/constants/config';
import { GlobalSelectors } from '@/containers/global/selectors';
import { globalActions } from '@/containers/global/slice';
import { AppPages } from '@/routes';
import styled from '@emotion/styled';

import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
interface ConnectButtonProps {
  location: string;
}

export const ConnectButton: FC<ConnectButtonProps> = ({ location }) => {
  const dispatch = useDispatch();
  const { account } = useSelector(GlobalSelectors.connection);
  const isChecking = useSelector(
    GlobalSelectors.isCheckingIfWalletIsRegistered
  );
  const isWalletRegistered = useSelector(GlobalSelectors.isWalletRegistered);

  const goToPlatform = () => {
    if (isWalletRegistered) {
      window.open(platformAddress, '_blank');
    } else {
      dispatch(
        globalActions.setGlobalModalOpen({
          modalName: 'setupWallMoneyEmail',
          isOpen: true,
        })
      );
    }
  };
  const connect = () => {
    // @ts-ignore
    const installed = !!window.coreport;
    if (installed) {
      dispatch(
        globalActions.setGlobalModalOpen({
          modalName: 'connectToCorePort',
          isOpen: true,
        })
      );
    } else {
      dispatch(
        globalActions.setGlobalModalOpen({
          isOpen: true,
          modalName: 'installCorePort',
        })
      );
    }
  };
  useEffect(() => {
    if (account) {
      dispatch(
        globalActions.checkIfWalletIsRegistered({
          coreId: account,
        })
      );
    }
  }, [account]);
  return (
    <StyledConnectButton
      isLoading={isChecking}
      disabled={isChecking}
      onClick={account ? goToPlatform : connect}
      location={location}
    >
      {account ? 'Enter the platform' : 'Connect to CorePort'}
    </StyledConnectButton>
  );
};

const StyledConnectButton = styled(WhiteBGButton)<{ location: string }>`
  min-width: 210px;
  background-color: ${(props) => {
    if (props.location === AppPages.Home) {
      return 'var(--light)';
    }
    return 'var(--white)';
  }};
`;
