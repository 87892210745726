import {
  COLUMN_ALIGN_START__SPACE_B,
  ROW_ALIGN_START__SPACE_B,
} from '@/styles/globalStyles';
import styled from '@emotion/styled';
import { FC, ReactElement } from 'react';
import Icon from '@/components/icon';
import contactUsIcon from '../../../assets/svg/contactUsBG.png';
import { WhiteBGButton } from '@/components/button/whiteBg';
import { Box } from '@mui/material';
import {
  TEXT_15_400,
  TEXT_11_700,
  TEXT_96_400,
} from '@/styles/globalTypography';
import { NavLink, useNavigate } from 'react-router-dom';
import { CONTACT_US_URL } from '@/constants/Links';

export const ContactUs: FC = (): ReactElement => {
  return (
    <ContactUsContainer>
      <LeftColumnContainer>
        <ContactUsBadge>Experience Lightning-Fast Transactions</ContactUsBadge>
        <ContactUsTitle>Want to reach out to us?</ContactUsTitle>
        <ContactUsDescription>
          You can use our concierge service inside the platform should you feel
          the need to reach out to us or alternatively you are welcome to send
          us an email
        </ContactUsDescription>
        <NavLink to={CONTACT_US_URL}>
          <ContactUsButton>Contact us</ContactUsButton>
        </NavLink>
      </LeftColumnContainer>
      <StyledIcon src={contactUsIcon} />
    </ContactUsContainer>
  );
};

const ContactUsContainer = styled(Box)`
  ${ROW_ALIGN_START__SPACE_B}
  width: 100%;
  height: 653px;
  overflow-y: clip;
`;

const LeftColumnContainer = styled(Box)`
  ${COLUMN_ALIGN_START__SPACE_B}
  gap: 24px;
  width: 451px;
  min-width: 451px;
`;

const ContactUsBadge = styled('p')`
  ${TEXT_11_700}
  color: var(--brand);
`;

const ContactUsTitle = styled('p')`
  ${TEXT_96_400}
  color: var(--black);
  font-family: 'bebas neue' !important;
`;

const ContactUsDescription = styled('p')`
  ${TEXT_15_400}
  color: var(--grey);
`;

const ContactUsButton = styled(WhiteBGButton)`
  margin-top: 40px;
  background-color: var(--light);
  width: 156px;
  height: 62px;
`;

const StyledIcon = styled(Icon)`
  transform: translateX(-750px) translateY(-150px);
  z-index: -1;
`;
