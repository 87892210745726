import { ConnectToWalletModal } from './ConnectToCoreport';
import { EmailIsSet } from './emailIsSet';
import { InstallCoreport } from './installCoreport';
import { SetupYourEmail } from './setupYourEmail';

export const GlobalModals = () => {
  return (
    <>
      <ConnectToWalletModal />
      <InstallCoreport />
      <SetupYourEmail />
      <EmailIsSet />
    </>
  );
};
