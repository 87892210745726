import { styled } from '@mui/material';
import { FC } from 'react';
import BaseButton, { BaseButtonProps } from '../basebutton';
import { TEXT_11_700 } from '@/styles/globalTypography';
interface Props extends BaseButtonProps {}
export const WhiteBGButton: FC<Props> = ({
  children,
  size = 'medium',
  ...props
}) => {
  return (
    <WhiteBG color="primary" size={size} {...props}>
      {children}
    </WhiteBG>
  );
};

const WhiteBG = styled(BaseButton)`
  color: var(--black);
  background-color: var(--white);
  white-space: nowrap;
  padding: 16px 32px;
  width: 210px;
  /* min-width: 210px; */
  border-radius: 4px;
  font-family: Poppins;
  ${TEXT_11_700}
  text-transform: uppercase;
  overflow: hidden;
  /* &:hover {
    background-color: var(--greyscale-600);
  }
  &:active {
    background-color: var(--greyscale-800);
  }
  &:disabled {
    color: var(--greyscale-50);
    background-color: var(--greyscale-300);
  } */
`;
