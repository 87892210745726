import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './types';

const initialState: GlobalState = {
  globalModals: {
    connectToCorePort: {
      isOpen: false,
    },
    installCorePort: {
      isOpen: false,
    },
    setupWallMoneyEmail: {
      isOpen: false,
    },
    wallMoneyEmailSuccessfullySet: {
      isOpen: false,
    },
  },
  isCheckingIfWalletIsRegistered: false,
  isRegisteringWallet: false,
  connection: undefined,
  isWalletRegistered: false,
};

// global slice
export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setGlobalModalOpen: (
      state,
      action: PayloadAction<{
        modalName: keyof GlobalState['globalModals'];
        isOpen: boolean;
      }>
    ) => {
      const { modalName, isOpen } = action.payload;
      state.globalModals[modalName].isOpen = isOpen;
    },
    setConnection(
      state,
      action: PayloadAction<{ account: string; connect: any }>
    ) {
      state.connection = action.payload;
    },
    checkIfWalletIsRegistered(
      state,
      action: PayloadAction<{ coreId: string }>
    ) {},
    setIsCheckingIfWalletIsRegistered(state, action: PayloadAction<boolean>) {
      state.isCheckingIfWalletIsRegistered = action.payload;
    },
    registerWallet(state, action: PayloadAction<{ email: string }>) {},
    setIsRegisteringWallet(state, action: PayloadAction<boolean>) {
      state.isRegisteringWallet = action.payload;
    },
    setIsEmailRegistered(state, action: PayloadAction<boolean>) {
      state.isWalletRegistered = action.payload;
    },
  },
});

export const { actions: globalActions, reducer: globalReducer } = globalSlice;
