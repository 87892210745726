import { CoreNetwork } from '@/hooks/network';
import { useConnection } from '@/hooks/useConnection';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { globalActions } from './slice';
export const NETWORK: CoreNetwork = {
  name: 'Core Main Network',
  networkId: 3,
  rpcUrl: 'https://xcbapi.corecoin.cc/',
  blockBookBaseUrl: 'https://devin.blockindex.net/api/v2',
  explorerUrl: 'https://devin.blockindex.net',
  nativeCurrency: {
    name: 'core',
    symbol: 'CRE',
    decimals: 18,
  },
  iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png',
};

export const Blockchain = () => {
  const dispatch = useDispatch();
  const { account, connect } = useConnection({
    autoConnect: true,
    network: NETWORK,
  });
  useEffect(() => {
    dispatch(
      globalActions.setConnection({
        account,
        connect,
      })
    );
  }, [account]);
  return <></>;
};
