import { styled } from '@mui/material';
import { FC } from 'react';
import { BUTTON } from '../../../styles/globalStyles';
import BaseButton, { BaseButtonProps } from '../basebutton';
interface Props extends BaseButtonProps {}
export const PrimaryButton: FC<Props> = ({
  children,
  size = 'medium',
  ...props
}) => {
  return (
    <Primary color="primary" size={size} {...props}>
      {children}
    </Primary>
  );
};

const Primary = styled(BaseButton)`
  color: var(--white) !important;
  background-color: var(--brand) !important;
  &:hover {
    background-color: var(--brand-hover) !important;
  }
  &:active {
    background-color: var(--brand-active) !important;
  }
  &:disabled {
    color: var(--greyscale-50) !important;
    background-color: var(--greyscale-300) !important;
  }
`;
