import { styled } from '@mui/material';

export const CopyIcon = (props: any) => {
  return (
    <Wrapper {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.4 20.5H9.6C9.26863 20.5 9 20.2314 9 19.9V10.1C9 9.76863 9.26863 9.5 9.6 9.5H19.4C19.7314 9.5 20 9.76863 20 10.1V19.9C20 20.2314 19.7314 20.5 19.4 20.5Z"
          stroke="#878490"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 9.5V5.1C15 4.76863 14.7314 4.5 14.4 4.5H4.6C4.26863 4.5 4 4.76863 4 5.1V14.9C4 15.2314 4.26863 15.5 4.6 15.5H9"
          stroke="#878490"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Wrapper>
  );
};
const Wrapper = styled('div')`
  cursor: pointer;
`;
