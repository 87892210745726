import { styled } from '@mui/material';

export const OperaIcon = (props: any) => {
  return (
    <Wrapper {...props}>
      <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.1181 31.4287C11.9061 28.8167 10.4741 24.9607 10.3781 20.6287C10.3781 20.6167 10.3781 19.6967 10.3781 19.6847C10.4741 15.3527 11.9061 11.4967 14.1181 8.88474C16.9901 5.16074 21.2541 2.79674 26.0142 2.79674C28.9422 2.79674 31.6862 3.69274 34.0302 5.24874C30.5102 2.09674 25.8701 0.176738 20.7782 0.156738C20.7542 0.156738 20.7262 0.156738 20.7022 0.156738C9.65815 0.156738 0.702148 9.11274 0.702148 20.1567C0.702148 30.8847 9.14615 39.6367 19.7502 40.1327C20.0662 40.1487 20.3822 40.1567 20.7022 40.1567C25.8221 40.1567 30.4942 38.2327 34.0302 35.0687C31.6862 36.6207 28.9461 37.5167 26.0142 37.5167C21.2541 37.5167 16.9901 35.1527 14.1181 31.4287Z"
          fill="url(#paint0_linear_79_1334)"
        />
        <path
          d="M14.1162 8.88439C15.9522 6.71639 18.3202 5.41239 20.9122 5.41239C26.7362 5.41239 31.4522 12.0124 31.4522 20.1564C31.4522 28.3004 26.7322 34.9004 20.9122 34.9004C18.3242 34.9004 15.9522 33.5924 14.1162 31.4284C16.9882 35.1524 21.2522 37.5164 26.0122 37.5164C28.9402 37.5164 31.6842 36.6204 34.0282 35.0684C38.1242 31.4044 40.7002 26.0804 40.7002 20.1564C40.7002 14.2324 38.1242 8.90839 34.0282 5.24839C31.6842 3.69239 28.9442 2.79639 26.0122 2.79639C21.2522 2.79639 16.9882 5.16039 14.1162 8.88439Z"
          fill="url(#paint1_linear_79_1334)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_79_1334"
            x1="17.3671"
            y1="0.808898"
            x2="17.3671"
            y2="39.5746"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.3" stopColor="#FF1B2D" />
            <stop offset="0.4381" stopColor="#FA1A2C" />
            <stop offset="0.5939" stopColor="#ED1528" />
            <stop offset="0.7581" stopColor="#D60E21" />
            <stop offset="0.9272" stopColor="#B70519" />
            <stop offset="1" stopColor="#A70014" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_79_1334"
            x1="27.4086"
            y1="3.09341"
            x2="27.4086"
            y2="37.3798"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9C0000" />
            <stop offset="0.7" stopColor="#FF4B4B" />
          </linearGradient>
        </defs>
      </svg>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  cursor: pointer;
`;
