import { Box, styled } from '@mui/material';

import { COLUMN_ALIGN_START__JUSTIFY_CENTER } from '@/styles/globalStyles';
import { H3_BOLD, TEXT_SM_REGULAR } from '@/styles/globalTypography';
import BaseButton from '@/components/button/basebutton';
import { useDispatch } from 'react-redux';
import { globalActions } from '@/containers/global/slice';
import { platformAddress } from '@/constants/config';

export const EmailIsSetContent = () => {
  const dispatch = useDispatch();
  const enterThePlatform = () => {
    window.open(platformAddress, '_blank');
  };
  const close = () => {
    dispatch(
      globalActions.setGlobalModalOpen({
        isOpen: false,
        modalName: 'wallMoneyEmailSuccessfullySet',
      })
    );
  };
  return (
    <Wrapper>
      <DiscriptionContainer>
        <Discription>Now you can log in to the application.</Discription>
        <Discription></Discription>
        <CancelButton onClick={enterThePlatform}>
          Enter the platform
        </CancelButton>
        <CancelButton onClick={close}>Close</CancelButton>
      </DiscriptionContainer>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 40px;
`;

const DiscriptionContainer = styled('div')`
  width: 100%;
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap:16px;
`;

const CancelButton = styled(BaseButton)`
  height: 64px;
  width: 100%;
  background: transparent;
  border: 1px solid var(--light);
  color: var(--darkText);
  ${H3_BOLD}
  font-size: 18px;
`;

const Discription = styled('div')`
  ${TEXT_SM_REGULAR}
  display: flex;
  gap: 12px;
  text-align: left;
  color: var(--Neutrals-N100);
  flex-direction: column;
  width: 100%;
`;
