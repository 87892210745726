import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material';

import Icon from '@/components/icon';

import CorePortIcon from 'assets/svg/coreport.svg';

import {
  COLUMN_ALIGN_START__JUSTIFY_CENTER,
  ROW_JUSTIFY_START__ALIGN_CENTER,
} from '@/styles/globalStyles';
import {
  H3_BOLD,
  H3_REGULAR,
  TEXT_SM_REGULAR,
} from '@/styles/globalTypography';
import { GlobalSelectors } from '@/containers/global/selectors';
import BaseButton from '@/components/button/basebutton';
import { globalActions } from '@/containers/global/slice';

export const ConnectToWalletContent = () => {
  const { connect } = useSelector(GlobalSelectors.connection);
  const dispatch = useDispatch();
  const close = () => {
    dispatch(
      globalActions.setGlobalModalOpen({
        isOpen: false,
        modalName: 'connectToCorePort',
      })
    );
  };
  const handleConnectToWallet = () => {
    connect();
  };

  return (
    <Wrapper>
      <Discription>
        All Assets, FIAT or Digital, at your fingers tips tailored for your
        lifestyle, all  in one platform.
      </Discription>
      <WalletContainer onClick={handleConnectToWallet}>
        <Icon src={CorePortIcon} />
        <WalletName>CorePort</WalletName>
      </WalletContainer>
      <DiscriptionContainer>
        {/* <Discription>
          Wallets are provided by third parties. By connecting your wallet, you
          agree to their terms and conditions. Wallet access may depend on these
          third parties being operational.
        </Discription> */}
        <CancelButton onClick={close}>Cancel</CancelButton>
      </DiscriptionContainer>
    </Wrapper>
  );
};

const Wrapper = styled('div')`
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap: 40px;
`;

const WalletContainer = styled('div')`
  ${ROW_JUSTIFY_START__ALIGN_CENTER}
  gap: 16px;
  width: 100%;
  padding: 0 24px;
  background-color: var(--light);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0px;
  cursor: pointer;
  z-index: 3;
  height: 75px;
  &:hover {
    opacity: 0.8;
  }
`;

const WalletName = styled('h3')`
  ${H3_REGULAR}
`;

const DiscriptionContainer = styled('div')`
  width: 100%;
  ${COLUMN_ALIGN_START__JUSTIFY_CENTER}
  gap:16px;
`;

const CancelButton = styled(BaseButton)`
  height: 64px;
  width: 100%;
  background: transparent;
  border: 1px solid var(--light);
  color: var(--darkText);
  ${H3_BOLD}
  font-size: 18px;
`;

const Discription = styled('p')`
  ${TEXT_SM_REGULAR}
  text-align: left;
  color: var(--lightText);
  font-size: 15px;
  span {
    color: var(--Neutrals-N50);
  }
`;
