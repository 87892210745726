import { ROW_ALIGN_CENTER__SPACE_B } from '@/styles/globalStyles';
import styled from '@emotion/styled';
import { FC, ReactElement } from 'react';
import { navItems } from '../constants';
import { Link, NavLink } from 'react-router-dom';
import { Box } from '@mui/material';
import { TEXT_11_700 } from '@/styles/globalTypography';

export const Navbar: FC = (): ReactElement => {
  return (
    <NavbarContainer>
      {navItems.map((item) => (
        <StyledLink
          key={item.title}
          to={item.path}
          // className={(navData) => (navData.isActive ? 'active' : '')}
        >
          {item.title}
        </StyledLink>
      ))}
    </NavbarContainer>
  );
};

const NavbarContainer = styled(Box)`
  ${ROW_ALIGN_CENTER__SPACE_B}
  gap:32px;
  padding: 0 32px;
  width: 100%;
  background-color: var(--Black);
`;

const StyledLink = styled(NavLink)`
  color: var(--grey);
  ${TEXT_11_700}
  text-transform: uppercase;
  text-decoration: none;
  &.active {
    color: var(--black);
  }
`;
