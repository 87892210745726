import {
  COLUMN_ALIGN_CENTER__SPACE_B,
  COLUMN_ALIGN_START__SPACE_B,
  ROW_ALIGN_CENTER__SPACE_B,
  ROW_ALIGN_START__SPACE_B,
} from '@/styles/globalStyles';
import styled from '@emotion/styled';
import { FC, ReactElement } from 'react';
import Icon from '@/components/icon';
import { WhiteBGButton } from '@/components/button/whiteBg';
import { Box } from '@mui/material';
import {
  TEXT_15_400,
  TEXT_11_700,
  TEXT_96_400,
  TEXT_16_400,
  TEXT_14_400,
  TEXT_14_600,
} from '@/styles/globalTypography';
import wallMoneyIcon from '../../../assets/svg/footer_logo.svg';
import wallMoneyName from '../../../assets/svg/footer_name.svg';
import {
  footerNavLinksCol1,
  footerNavLinksCol2,
  footerNavLinksCol3,
} from '../constant';
import { Link } from 'react-router-dom';

export const FooterNavbar: FC = (): ReactElement => {
  return (
    <FooterNavbarContainer>
      <LeftColumnContainer>
        <Icon src={wallMoneyIcon} />
        <Icon src={wallMoneyName} />
        <LeftColumnText>
          Lifestyle Platform  Bridges The Gap between Digital Assets and FIAT,
          simple, fast and secure.
        </LeftColumnText>
      </LeftColumnContainer>
      <RightColumnContainer>
        <LinkWrapper>
          <ColumnTitle>{footerNavLinksCol3[0].title}</ColumnTitle>
          {footerNavLinksCol3.slice(1).map((item) => {
            return (
              <Link key={item.title} to={item.path}>
                {item.title}
              </Link>
            );
          })}
        </LinkWrapper>
        <LinkWrapper>
          <ColumnTitle>{footerNavLinksCol1[0].title}</ColumnTitle>
          {footerNavLinksCol1.slice(1).map(({ title, path }) => {
            return path === 'scroll' ? (
              <NonLinkItem
                key={title}
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              >
                {title}
              </NonLinkItem>
            ) : (
              <Link
                key={title}
                to={path}
                target="_blank"
                rel="noopener noreferrer"
              >
                {title}
              </Link>
            );
          })}
        </LinkWrapper>
        <LinkWrapper>
          <ColumnTitle>{footerNavLinksCol2[0].title}</ColumnTitle>
          {footerNavLinksCol2.slice(1).map((item) => {
            return (
              <Link
                key={item.title}
                to={item.path}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.title}
              </Link>
            );
          })}
        </LinkWrapper>
      </RightColumnContainer>
    </FooterNavbarContainer>
  );
};

const FooterNavbarContainer = styled(Box)`
  ${ROW_ALIGN_START__SPACE_B}
  width: 100%;
  margin-top: 100px;
`;

const LeftColumnContainer = styled(Box)`
  ${COLUMN_ALIGN_START__SPACE_B}
  gap: 16px;
  width: 301px;
`;

const LeftColumnText = styled('p')`
  ${TEXT_16_400}
  color: var(--black);
`;

const RightColumnContainer = styled(Box)`
  ${ROW_ALIGN_START__SPACE_B}
  gap: 72px
`;

const LinkWrapper = styled(Box)`
  ${COLUMN_ALIGN_START__SPACE_B}
  gap: 16px;
  a {
    ${TEXT_14_400}
    color: var(--grey);
    text-decoration: none;
    &:hover {
      color: var(--black);
    }
  }
`;

const ColumnTitle = styled('p')`
  color: var(--black);
  ${TEXT_14_600}
  &:hover {
    cursor: default;
  }
`;

const NonLinkItem = styled('p')`
  ${TEXT_14_400}
  color: var(--grey);
  text-decoration: none;
  &:hover {
    color: var(--black);
    cursor: pointer;
  }
`;
