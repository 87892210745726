import { useDispatch, useSelector } from 'react-redux';

import { SetupYourEmailContent } from './content';
import { Modal } from '@/components/modal';
import { GlobalSelectors } from '@/containers/global/selectors';
import { globalActions } from '@/containers/global/slice';

export const SetupYourEmail = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(GlobalSelectors.isSetupWallMoneyEmailModalOpen);
  const handleModalClose = () => {
    dispatch(
      globalActions.setGlobalModalOpen({
        modalName: 'setupWallMoneyEmail',
        isOpen: false,
      })
    );
  };

  return (
    <Modal
      title={'Welcome. Set up your email.'}
      isOpen={isOpen}
      onClose={handleModalClose}
    >
      <SetupYourEmailContent />
    </Modal>
  );
};
